import { updateMember } from '@wix/ambassador-members-v1-member/http';
import { assignRole, unassignRole } from '@wix/ambassador-members-v1-role/http';
import type {
  AssignRoleResponse,
  UnassignRoleResponse,
} from '@wix/ambassador-members-v1-role/types';
import {
  followMember as followMemberRequest,
  unfollowMember as unfollowMemberRequest,
} from '@wix/ambassador-members-v3-follow/http';
import type {
  FollowMemberResponse,
  UnfollowMemberResponse,
} from '@wix/ambassador-members-v3-follow/types';
import { withResolvedError } from '@wix/members-area-commons-ts';
import type {
  MediaPlatformImage,
  PrivacyStatus,
  RoleId,
} from '@wix/members-domain-ts';
import type { HttpResponse, TFunction } from '@wix/yoshi-flow-editor';

import type {
  InjectedSite,
  PartialUpdatableFields,
  PublicMember,
  PublicMemberInfoUpdateOptions,
} from '../../types';
import type { ErrorHandler, HttpClient } from '../../types/controller';
import { getAssignRoleApplicationErrors } from './application-errors';

type InstalledAppsMap = InjectedSite['installedApps'];

type SocialChatResponse = { isSocialChat: boolean };

type ApplyRoleActionOptions = {
  uid: string;
  roles: RoleId[];
  roleId: RoleId;
};

type MediaCredentialsResponse = {
  uploadToken: string;
  uploadUrl: string;
};

type MembersServiceOptions = {
  baseUrl: string;
  httpClient: HttpClient;
  errorHandler: ErrorHandler;
  t: TFunction;
};

export class MembersService {
  private readonly baseUrl: string;
  private readonly httpClient: HttpClient;
  private readonly errorHandler: ErrorHandler;
  private readonly t: TFunction;

  constructor({ baseUrl, httpClient, errorHandler, t }: MembersServiceOptions) {
    this.baseUrl = baseUrl;
    this.httpClient = httpClient;
    this.errorHandler = errorHandler;
    this.t = t;
  }

  readonly getMember = async (uid: string) => {
    const requestFn = () =>
      this.httpClient.get<PublicMember>(`${this.baseUrl}/members/${uid}`);

    const { data } = await withResolvedError(requestFn, this.errorHandler, {
      errorCodesMap: {},
    });

    return data;
  };

  readonly partialMemberUpdate = async (
    uid: string,
    updatedFields: PartialUpdatableFields,
  ) => {
    const requestFn = () =>
      this.httpClient.patch<PublicMember>(
        `${this.baseUrl}/members/${uid}`,
        updatedFields,
      );

    const { data } = await withResolvedError(requestFn, this.errorHandler, {
      errorCodesMap: {},
    });

    return data;
  };

  readonly setCurrentMemberPrivacyStatus = async (
    privacyStatus: PrivacyStatus,
  ) => {
    const requestFn = () =>
      this.httpClient.put<PrivacyStatus>(
        `${this.baseUrl}/members/me/privacy-status`,
        { privacyStatus },
      );

    const { data } = await withResolvedError(requestFn, this.errorHandler, {
      errorCodesMap: {},
    });

    return data;
  };

  readonly setMemberBadges = async (uid: string, badgeIds: string[]) => {
    const requestFn = () =>
      this.httpClient.post(`${this.baseUrl}/members/${uid}/badges`, {
        badgeIds,
      });

    const { data } = await withResolvedError(requestFn, this.errorHandler, {
      errorCodesMap: {},
    });

    return data;
  };

  readonly deleteMember = async (uid: string) => {
    const requestFn = () =>
      this.httpClient.delete(`${this.baseUrl}/members/${uid}`);

    const { data } = await withResolvedError(requestFn, this.errorHandler, {
      errorCodesMap: {},
    });

    return data;
  };

  readonly applyRoleAction = async ({
    uid,
    roleId,
    roles,
  }: ApplyRoleActionOptions): Promise<
    HttpResponse<UnassignRoleResponse | AssignRoleResponse>
  > => {
    const roleKey = roleId.toUpperCase();

    return roles.includes(roleId)
      ? this.unAssignRole(uid, roleKey)
      : this.assignRole(uid, roleKey);
  };

  readonly getInstalledApps = async () => {
    const url = `${this.baseUrl}/site/installed-apps`;

    const requestFn = () => this.httpClient.get<InstalledAppsMap>(url);

    const { data } = await withResolvedError(requestFn, this.errorHandler, {
      errorCodesMap: {},
    });

    return data;
  };

  readonly getIsSocialChat = async () => {
    const url = `${this.baseUrl}/site/social-chat`;

    const requestFn = () => this.httpClient.get<SocialChatResponse>(url);

    const { data } = await withResolvedError(requestFn, this.errorHandler, {
      errorCodesMap: {},
    });

    return data.isSocialChat;
  };

  readonly toggleMemberFollowStatus = async (
    memberId: string,
    isSubscribed: boolean,
  ): Promise<UnfollowMemberResponse | FollowMemberResponse> => {
    return isSubscribed
      ? this.unfollowMember(memberId)
      : this.followMember(memberId);
  };

  readonly updateMemberPublicInfo = async (
    memberData: PublicMemberInfoUpdateOptions,
  ) => {
    const requestFn = () =>
      this.httpClient.request(
        updateMember({
          member: memberData,
        }),
      );

    const { data } = await withResolvedError(requestFn, this.errorHandler, {
      errorCodesMap: {
        applicationError: {
          INVALID_CUSTOM_FIELD_URL: false, // we don't use custom fields within profile card
        },
      },
    });

    return data;
  };

  readonly getMediaPlatformCredentials = async () => {
    const url = `${this.baseUrl}/media/credentials/picture`;

    const requestFn = () => this.httpClient.get<MediaCredentialsResponse>(url);

    const { data } = await withResolvedError(requestFn, this.errorHandler, {
      errorCodesMap: {},
    });

    return data;
  };

  readonly setMediaPlatformPictureDetails = async (
    picture: MediaPlatformImage,
  ) => {
    const requestFn = () =>
      this.httpClient.post(`${this.baseUrl}/media/index`, picture);

    const { data } = await withResolvedError(requestFn, this.errorHandler, {
      errorCodesMap: {},
    });

    return data;
  };

  private readonly followMember = async (memberId: string) => {
    const requestFn = () =>
      this.httpClient.request(followMemberRequest({ memberId }));

    const { data } = await withResolvedError(requestFn, this.errorHandler, {
      errorCodesMap: {},
    });

    return data;
  };

  private readonly unfollowMember = async (memberId: string) => {
    const requestFn = () =>
      this.httpClient.request(unfollowMemberRequest({ memberId }));

    const { data } = await withResolvedError(requestFn, this.errorHandler, {
      errorCodesMap: {},
    });

    return data;
  };

  private readonly assignRole = (memberId: string, roleKey: string) => {
    const requestFn = () =>
      this.httpClient.request(assignRole({ memberId, roleKey }));

    return withResolvedError(requestFn, this.errorHandler, {
      errorCodesMap: {
        applicationError: getAssignRoleApplicationErrors(this.t),
      },
    });
  };

  private readonly unAssignRole = (memberId: string, roleKey: string) => {
    const requestFn = () =>
      this.httpClient.request(unassignRole({ memberId, roleKey }));

    return withResolvedError(requestFn, this.errorHandler, {
      errorCodesMap: {},
    });
  };
}
