import type { TFunction } from '@wix/yoshi-flow-editor';

export const getReportMemberApplicationErrors = (t: TFunction) => ({
  SELF_REPORTING_FORBIDDEN: () => ({
    message: t('MemberRoles.action_set.report.error.self-reporting-forbidden'),
  }),
  CANNOT_REPORT_BLOCKED_MEMBER: () => ({
    message: t(
      'MemberRoles.action_set.report.error.cannot-report-blocked-member',
    ),
  }),
});
