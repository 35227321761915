import type { Category } from '@wix/ambassador-members-v1-member-report/types';
import { reportMemberSubmitted } from '@wix/bi-logger-members-app-uou/v2';
import type { RoleId } from '@wix/members-domain-ts';

import { Experiment } from '../../../../constants/experiments';
import { ToastSkin } from '../../../../constants/toast';
import { getCommonBIEventsData } from '../../../../services/bi-event';
import { openModal, openModalWithCallback } from '../../../../services/modal';
import type { ThunkWithArgs } from '../../../../types';
import { ReportCategory } from '../../../../types';
import { showToast } from '../../common';

type OnConfirmProps = {
  data: {
    category: number;
    description: string;
  };
};

const THANKS_FOR_REPORT_MODAL = 'thanks-for-report';

const reportCategoryMap = [
  ReportCategory.Spam,
  ReportCategory.Impersonation,
  ReportCategory.Harassment,
  ReportCategory.Other,
];

export const reportMember: ThunkWithArgs<RoleId> =
  (roleId) => async (dispatch, getState, extra) => {
    const {
      compId,
      wixCodeApi,
      platformAPIs,
      experiments,
      biLogger,
      flowAPI,
      metaData,
      reportMemberService,
      errorHandlerService,
    } = extra;

    const state = getState();
    const { viewed, current } = state.users;
    const { uid } = viewed;

    const onConfirm = async ({ data }: OnConfirmProps) => {
      const reason = reportCategoryMap[data.category] as unknown as Category;
      const details = data.description;

      const showNewNotifications = experiments.enabled(
        Experiment.ShowNewNotificationsContent,
      );

      try {
        await reportMemberService.reportMember(uid, current?.uid!, {
          category: reason,
          description: details,
        });

        biLogger?.report(
          reportMemberSubmitted({
            ...getCommonBIEventsData(flowAPI, state, metaData),
            member_chosen: uid,
            siteMemberId: state.users.current?.uid,
            details,
            reason,
            visitor_id: platformAPIs.bi?.visitorId,
          }),
        );

        openModal({
          compId,
          modalType: THANKS_FOR_REPORT_MODAL,
          wixCodeApi,
          experiments,
          payload: {},
        });
      } catch (error) {
        if (showNewNotifications) {
          const { message } = errorHandlerService.extractErrorData(error);

          showToast(dispatch, {
            message,
            skin: ToastSkin.error,
          });
        }
      }
    };

    openModalWithCallback({
      compId,
      modalType: roleId,
      payload: {},
      platformAPIs,
      wixCodeApi,
      experiments,
      onConfirm,
    });
  };
