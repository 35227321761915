import type { TFunction } from '@wix/yoshi-flow-editor';

export const getBlockedMemberApplicationErrors = (
  memberName: string,
  t: TFunction,
) => ({
  MEMBER_ALREADY_BLOCKED: () => ({
    message: t(
      'MemberRoles.action_set.block_member.error.member-already-blocked',
      {
        memberName,
      },
    ),
  }),
  SELF_BLOCKING_FORBIDDEN: () => ({
    message: t(
      'MemberRoles.action_set.block_member.error.self-blocking-forbidden',
    ),
  }),
  ADMIN_BLOCKING_FORBIDDEN: () => ({
    message: t(
      'MemberRoles.action_set.block_member.error.admin-blocking-forbidden',
    ),
  }),
});
