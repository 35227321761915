import { reportMember } from '@wix/ambassador-members-v1-member-report/http';
import type { Reason } from '@wix/ambassador-members-v1-member-report/types';
import { withResolvedError } from '@wix/members-area-commons-ts';
import type { TFunction } from '@wix/yoshi-flow-editor';

import type { ErrorHandler, HttpClient } from '../../types/controller';
import { getReportMemberApplicationErrors } from './application-errors';

type ReportMemberServiceOptions = {
  httpClient: HttpClient;
  errorHandler: ErrorHandler;
  t: TFunction;
};

export class ReportMemberService {
  private readonly httpClient: HttpClient;
  private readonly errorHandler: ErrorHandler;
  private readonly t: TFunction;

  constructor({ httpClient, errorHandler, t }: ReportMemberServiceOptions) {
    this.httpClient = httpClient;
    this.errorHandler = errorHandler;
    this.t = t;
  }

  async reportMember(
    reportedMemberId: string,
    reportingMemberId: string,
    reason: Reason,
  ) {
    const requestFn = () =>
      this.httpClient.request(
        reportMember({
          memberReport: { reportedMemberId, reportingMemberId, reason },
        }),
      );

    return withResolvedError(requestFn, this.errorHandler, {
      errorCodesMap: {
        applicationError: getReportMemberApplicationErrors(this.t),
      },
    });
  }
}
